import React from 'react'

// Components
import Footer from '../components/Footer'
import SEO from '../components/seo'

// Style
import style from './contact.module.css'

const Contact = ({
	location
}: {
	location: {
		href: string
	}
}) => (
	<section className={ style.Contact }>
		<SEO title='Contact' url={ location.href } />
		<h1 className={ style.h1 }>A<br />Long-haired<br />Handsome<br />Jesus</h1>
		<h2>Contact</h2>
		<p>If you’d like to get in touch with Michael Blankenburg, the best way is to email directly at <a href="mailto:burg66@gmail.com">burg66@gmail.com</a>. You can reach Brandon Durham at <a href="mailto:brandon@smallparade.com">brandon@smallparade.com</a>, or Corbin Lewars at <a href="mailto:corbin@corbinlewars.com">corbin@corbinlewars.com</a>.</p>
		<Footer />
	</section>
)

export default Contact
