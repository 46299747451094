import React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Style
import style from './index.module.css'

const date = new Date()
const year = date.getFullYear()

const Footer = () => (
	<footer className={ style.Footer }>
		<div className={ style.Left }>
			&copy; { year } A Memoir Project
		</div>
		<div className={ style.Right }>
			<a
				href="https://confirmsubscription.com/h/d/80D85D6A4A8CF261"
				onClick={
					() => {
						trackCustomEvent({
							category: "Footer Subscribe",
							action: "Click",
						})
					}
				}
			>Subscribe for Updates</a>
		</div>
	</footer>
)

export default Footer
